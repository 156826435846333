<template>
  <div>
    <PageContainer
      :title="$translations.buttons['schedule-orientation']"
      :back="actions.back"
    >
      <template v-slot:content>
        <div class="page-segment margin-top" v-if="specialist">
          <div class="page-segment-content">
            <div v-html="$translations['recommended-specialist'].description" />
            <div class="divisor"></div>
            <div
              class="heading margin-bottom"
              v-text="$translations['recommended-specialist'].orientator"
            />

            <!-- specialist card -->
            <PersonCard
              v-if="specialist"
              class="margin-y"
              :title="specialistFullName"
              :image="specialistImage"
              :middle-line="specialistSpeciality"
            />

            <AppointmentDateSelector
              v-if="option === 'video-call'"
              :specialist-id="specialist.id"
              :service-id="specialist.service_id"
              :service-modality="'VIRTUAL'"
              @changed="setDate"
            />

            <SubmitButton
              v-if="date"
              @submit="bookAppointment"
              :text="
                $translations['recommended-specialist']['book-appointment']
              "
              :loading="loading"
              :isValid="true"
              :isNotValid="false"
            />
          </div>
        </div>
      </template>
    </PageContainer>

    <!-- modals -->
    <FullScreenDialog
      :title="modal.title"
      :subtitle="modal.subtitle"
      :illustration="modal.illustration"
      @close="modal.action"
      v-if="modal.isVisible"
    >
      <template v-slot:body>
        <div>
          <UsersAppointmentCard :appointment="appointment" />

          <div class="buttons-container margin-top">
            <Button
              @clicked="openVideoCallRecommendations"
              :text="$translations.success['button/view-recomendations']"
            />
            <Button
              @clicked="$router.push({ name: 'Home' })"
              :text="$translations.success['button/go-home']"
              type="outline"
            />
          </div>
        </div>
      </template>
    </FullScreenDialog>

    <FullScreenDialog
      v-if="loadingSpecialist"
      :show-icon-loader="true"
      :can-be-closed="false"
      loader-name="selia-spinner"
      :card-style="false"
    >
      <template v-slot:body>
        <div class="recommended-specisliast-spinner">
          <div
            class="content"
            v-text="
              $translations['recommended-specialist']['modal-get-specialist']
                .title
            "
          />
          <div
            class="content-small"
            v-text="
              $translations['recommended-specialist']['modal-get-specialist']
                .subtitle
            "
          />
        </div>
      </template>
    </FullScreenDialog>
  </div>
</template>

<script>
import {
  Appointments,
  RecommendSpecialist,
  FullScreenDialog,
  IlustrationName,
  UpcomingAppointments,
  PageContainer,
  Button,
  PersonCard
} from '@seliaco/red-panda'

import AppointmentDateSelector from '@/components/fields/v2/AppointmentDateSelector'
import SubmitButton from '@/components/buttons/SubmitButton'
import { BookingAndAppointmentDetailEvent } from '@/types/events'
import { userStatus } from '@/types/state'
import { Browser } from '@capacitor/browser'
import { mapGetters } from 'vuex'
import UsersAppointmentCard from '@/components/appointment/AppointmentCard'
import { VideoCallRecommendations } from '@/constants/videocall-recommendations'

export default {
  name: 'RecommendedSpecialist',
  components: {
    SubmitButton,
    AppointmentDateSelector,
    PageContainer,
    FullScreenDialog,
    UsersAppointmentCard,
    Button,
    PersonCard
  },
  data () {
    return {
      loading: false,
      loadingSpecialist: false,
      specialist: null,
      availableDateTimes: [],
      disableDates: [],
      option: 'video-call',
      date: null,
      modal: {
        isVisible: false,
        title: this.$translations.appointments.modals.scheduled.title,
        subtitle: this.$translations.appointments.modals.scheduled.subtitle,
        illustration: IlustrationName.success,
        action: () => this.$router.push({ name: 'Home' })
      },
      modalities: [
        {
          action: 'message',
          icon: 'icon-message-circle-off',
          text: this.$translations['recommended-specialist'].option1
        },
        {
          action: 'video-call',
          icon: 'icon-video-outline-off',
          text: this.$translations['recommended-specialist'].option2
        }
      ],
      appointmentId: null,
      appointment: null,
      actions: {
        back: {
          callback: () => {
            if (this.$route.query.next) {
              this.$router.replace({
                name: this.$route.query.next
              })
            } else if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.go(-1)
            }
          }
        }
      },
      recommendationsUrl: VideoCallRecommendations
    }
  },
  mounted () {
    this.$globalEvent.$on('refresh/appointment', () => {
      if (this.$router.history.current.name === 'Recommended Specialist') {
        this.$router.push({ name: 'Home' })
      }
    })

    this.loadingSpecialist = true
    this.getSpecialist()
  },
  methods: {
    setDate (value) {
      if (!value || (value && !value.code)) {
        this.date = null
        return
      }

      this.date = value.code
    },
    getSpecialist () {
      RecommendSpecialist.get('ORIENTATION')
        .then((specialist) => {
          this.specialist = specialist
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error.default
          })
        })
        .finally(() => {
          this.loadingSpecialist = false
        })
    },
    bookAppointment () {
      this.loading = true

      Appointments.create({
        reason: 'Orientación',
        origin: 'USER',
        specialist_service: this.specialist.service.id,
        specialist: this.specialist.id,
        patient: this.$store.getters['auth/user'].id,
        virtual: true,
        starts_at: this.date
      })
        .then(async (response) => {
          await this.$store
            .dispatch('auth/fetchUser', {
              ...this.user,
              status: userStatus.waiting_for_orientation
            })
            .then(() => {})

          const userAppointment = await UpcomingAppointments.getMyAppointments(
            'USER'
          )

          this.appointment = {
            ...userAppointment.appointment,
            service: userAppointment.appointment.specialist_service
          }

          delete this.appointment.specialist_service

          this.appointmentId = this.appointment.id
          this.modal.isVisible = true

          this.$segment.track(
            BookingAndAppointmentDetailEvent.select_bookappointment,
            {
              appointment_id: response.id,
              patient_name: response.patient_name,
              specialist_name: response.specialist_name,
              starts_at: response.starts_at,
              day: this.$moment(response.starts_at).format('DD/MM/YYYY'),
              time: this.$moment(response.starts_at).format('HH:mm'),
              service_name: response.service_name,
              status: response.status,
              created_at: response.created_at
            }
          )
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error.default
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async openVideoCallRecommendations () {
      const lang = this.$store.getters.language.lang
      await Browser.open({
        url: this.recommendationsUrl[lang]
      })
    }
  },
  computed: {
    timeZone () {
      return this.$store.getters.userTimezone
    },
    specialistFullName () {
      return `${this.specialist?.user?.first_name} ${this.specialist?.user?.last_name}`
    },
    specialistImage () {
      return this.specialist?.user?.profile_picture
    },
    specialistSpeciality () {
      return this.specialist?.type.name
    },
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>

<style lang="sass">
.divisor
  background: var(--gray-10)
  width: calc(100% - 16px)
  height: 1px
  margin: 24px 0

.buttons-container
  display: flex
  flex-direction: column
  gap: 16px

.recommended-specisliast-spinner
  max-width: 480px
  text-align: center
  margin: auto
</style>
