<template>
  <div class="content-small text-gray-50" v-text="timeZone" />
</template>

<script>
export default {
  name: 'TimeZoneSelector',
  computed: {
    timeZone () {
      return this.$store.getters.userTimezone
    }
  }
}
</script>
