<template>
  <div class="appointment-date-selector">
    <DateSlotPicker
      v-model="date"
      :available-dates="availableDateTimesRaw"
      :loading="loading"
      :lang="$store.getters.language.lang"
      :range="limit"
      :start-date="start.toDate()"
      :end-date="end.toDate()"
      :num-slots="numSlots"
      @date-change="onChanged"
      @load-dates="loadSchedule"
      v-if="!loadingExternal"
    />

    <div class="margin-left" v-if="hasInfoExtra">
      <TimeZoneSelector class="margin-y" />

      <template>
        <InfoData
          icon="icon-timer-calendar-off"
          :text="bannerText"
          v-if="time"
        />

        <div
          class="content-small text-gray-50"
          v-text="
            $translations['specialist-page'].availability['date-banner'].empty
          "
          v-else
        />
      </template>
    </div>
  </div>
</template>

<script>
import {
  SpecialistsSchedulesServices,
  InfoData,
  DateSlotPicker
} from '@seliaco/red-panda'
import TimeZoneSelector from '@/components/fields/TimeZoneSelector.vue'

export default {
  name: 'AppointmentDateSelector',
  components: {
    InfoData,
    DateSlotPicker,
    TimeZoneSelector
  },
  props: {
    loadingExternal: {
      type: Boolean,
      default: false
    },
    specialistId: String,
    serviceId: String,
    serviceModality: {
      type: String,
      default: 'VIRTUAL'
    },
    numSlots: {
      type: Number,
      default: 5
    },
    hasInfoExtra: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      limit: 6,
      date: new Date(),
      time: null,
      availableDateTimesRaw: [],
      loading: false,
      bannerText: null,
      start: null,
      end: null
    }
  },
  methods: {
    getScheduleRange (date = new Date()) {
      if (!(this.specialistId && this.serviceId)) {
        return
      }

      this.loading = true

      this.start = this.$moment(date)
      this.end = this.$moment(date).add(this.limit, 'day')

      SpecialistsSchedulesServices.getBetweenDate({
        specialistId: this.specialistId,
        serviceId: this.serviceId,
        params: {
          start: this.start.format('YYYY-MM-DD'),
          end: this.end.format('YYYY-MM-DD'),
          timeZone: this.$store.getters.userTimezone,
          modality: this.serviceModality
        }
      })
        .then((availableDateTimes) => {
          this.availableDateTimesRaw = availableDateTimes || []
        })
        .catch()
        .finally(() => (this.loading = false))
    },
    loadSchedule (value) {
      this.getScheduleRange(value)
    },
    onChanged (value) {
      if (value) {
        this.time = value.code
        this.$emit('changed', value)
        this.getBannerText()
      }
    },
    getBannerText () {
      const dateSelected = this.$moment(this.time)
      const today = this.$moment()
      const tomorrow = this.$moment().add(1, 'day')
      const translations =
        this.$translations['specialist-page'].availability['date-banner']

      let day = 'dddd'

      if (dateSelected.isSame(today, 'd')) {
        day = `[${translations.today}]`
      }

      if (dateSelected.isSame(tomorrow, 'd')) {
        day = `[${translations.tomorrow}]`
      }

      const momentFormat = `${day}, D [${translations.of}] MMMM [${translations.at}] hh:mm a`

      this.bannerText = `${translations.appointment} ${dateSelected
        .tz(this.$store.getters.userTimezone)
        .format(momentFormat)}`
    }
  },
  watch: {
    serviceModality: {
      handler () {
        this.date = null
        this.getScheduleRange()
      }
    },
    serviceId: {
      handler () {
        this.date = null
        this.getScheduleRange()
      },
      immediate: true
    },
    date: {
      handler () {
        this.onChanged()
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass">
.appointment-date-selector
  background: white
  border-radius: 16px
  padding: 0 16px
</style>
