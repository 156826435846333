<template>
  <button
    type="submit"
    :class="buttonClass"
    :id="customId"
    class="heading-tag text-white relative text-center shadow-purple transition duration-300 ease-in-out h-full rounded-lg"
    @click="$emit('submit')"
  >
    {{ text }}

    <span class="absolute h-2 right-2 top-1.5 z-10" v-if="loading">
      <LoaderSmall />
    </span>
  </button>
</template>

<script>
import LoaderSmall from '@/components/LoaderSmall'

export default {
  name: 'SubmitButton',
  components: { LoaderSmall },
  props: {
    text: String,
    loading: Boolean,
    isValid: Boolean,
    floating: Boolean,
    customId: String,
    color: {
      type: String,
      default: 'white'
    }
  },
  computed: {
    buttonClass () {
      return {
        [`text-${this.color}`]: true,
        'bg-black-20 opacity-50 text-black-50': !this.isValid,
        'bg-purple opacity-50 text-white': this.loading,
        'bg-purple opacity-100 text-white': !this.loading && this.isValid,
        'pointer-events-none': this.loading || !this.isValid,
        'big margin-top relative w-full': !this.floating,
        'bottom-5 fixed z-10 big full': this.floating
      }
    }
  }
}
</script>
